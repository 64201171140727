import { initializeApp } from "open-basin";

const config = {
    network: "MUMBAI",
    provider: {
        address: process.env.REACT_APP_BASIN_ADDRESS,
        key: process.env.REACT_APP_BASIN_KEY,
        id: process.env.REACT_APP_BASIN_ID
    }
}

const basin = initializeApp(config);

export default basin;

