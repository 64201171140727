
import { ProviderModel } from "../models/ProviderModel";
import remote from "./remote";


const getProvider = async (address: string): Promise<ProviderModel> => {
    const data = await remote.get.doc("providers_public", address);

    return {
        id: data.id,
        name: data.name,
        image: data.image
    }
}

const api = {
    getProvider
}

export default api;