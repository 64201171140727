import React from 'react';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import auth from '../../services/auth';
import storage from '../../services/storage';
import { PrimaryButton } from '../components/buttons';
import { ImageInput } from '../components/input';
import { uuidv4 } from '@firebase/util';
import { Navigate } from 'react-router-dom';

const SignInView = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    return <div>
        <Form className='form'>
            <Form.Control
                className='text-box'
                type="email"
                value={email}
                onChange={(e) => { setEmail(e.target.value) }}
                placeholder="Email"
            />
            <br />
            <Form.Control
                className='text-box'
                type="password"
                value={password}
                onChange={(e) => { setPassword(e.target.value) }}
                placeholder="Password"
            />
            <br />
        </Form>
        <PrimaryButton title="Continue" type="fill" action={() => auth.signInWithEmail(email, password)}
        />
    </div>
}


// const SignUpView = () => {
//     const [name, setName] = useState("");
//     const [email, setEmail] = useState("");
//     const [password, setPassword] = useState("");
//     const [image, setImage] = useState<any | null>(null);

//     const createUser = async () => {
//         const imageUrl = await storage.upload('user/avatars/' + uuidv4(), image);

//         auth.createUserWithEmail(name,
//             email,
//             password,
//             imageUrl,
//             (user) => console.log(user))
//     }

//     return <div>
//         <div className='title-group'>
//             {ImageInput(null, (url: any) => {
//                 setImage(url);
//             })}
//             <br />
//             <p className='heading'>Create Account</p>
//         </div>
//         <br />
//         <Form className='form'>
//             <Form.Control
//                 className='text-box'
//                 type="name"
//                 value={name}
//                 onChange={(e) => { setName(e.target.value) }}
//                 placeholder="Name"
//             />
//             <br />
//             <Form.Control
//                 className='text-box'
//                 type="email"
//                 value={email}
//                 onChange={(e) => { setEmail(e.target.value) }}
//                 placeholder="Email"
//             />
//             <br />
//             <Form.Control
//                 className='text-box'
//                 type="password"
//                 value={password}
//                 onChange={(e) => { setPassword(e.target.value) }}
//                 placeholder="Password"
//             />
//             <br />
//             <Form.Check className='check-box' type="checkbox" label="I agree to the terms & service" />
//             <br />
//         </Form>
//         {
//             <PrimaryButton title="Continue" type="fill" action={async () => {
//                 await createUser()
//             }}
//             />
//         }
//     </div>
// }

export {
    SignInView,
    // SignUpView
};