


import { httpsCallable } from "firebase/functions";
import { funcs } from "../firebase";


const requestBucketAuthorization = async (bucket: string, providerAddress: string) => {
    const func = httpsCallable(funcs, 'requestBucketAuthorization');

    const result = await func({
        bucket: bucket,
        provider: providerAddress
    });

    return result;
}

const requestBucketsAuthorizations = async (buckets: string[], providerAddress: string) => {
    const func = httpsCallable(funcs, 'requestBucketsAuthorizations');

    const result = await func({
        buckets: buckets,
        provider: providerAddress
    });

    return result;
}

const functions = {
    requestBucketAuthorization,
    requestBucketsAuthorizations
}

export default functions;