
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { MainFooter } from './components/footer';
import { ImageSize, CircleImage, SquareImage } from "./components/images";
import "./Layout.css";
const logo = require('./assets/logo-black.png');


const LayoutView = (props: any) => {
    if (!props.user) return null;

    return <>
        <header className="header">
            <Link to="/">
                <button className="wrapper-button">
                    <div className="header-group">
                        {SquareImage(logo, "logo", ImageSize.small)}
                        <p className="header-basin-name">Open Basin</p>
                    </div>
                </button>
            </Link>
            <div className="header-group">
                <Link className="header-item link" to="/apps">Apps</Link>
                <Link className="header-item link" to="/wallet">Wallet</Link>
                <Link className="header-item link" to="/settings">Settings</Link>
                <span className="header-item"></span>
                <Link to="/settings">
                    {CircleImage(props.user.image, "avatar", ImageSize.small)}
                </Link>
            </div>
        </header>
        <div className="layout-body">
            <Outlet />
        </div>
        <MainFooter />
    </>
}

export default LayoutView;