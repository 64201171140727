import firebase from '../firebase';
import remote from './remote';
import basin from '../basin';
import { UserModel } from '../models/UserModel';

// const signInWithGoogle = (completion: (user: firebase.User | null) => void) => {
//     chrome.identity.getAuthToken({}, (token) => {
//         let credential = firebase.auth.GoogleAuthProvider.credential(null, token)
//         firebase
//             .auth()
//             .signInWithCredential(credential)
//             .then((userCredential) => {
//                 const user = firebase.auth().currentUser
//                 completion(user);
//             })
//             .catch((error: any) => {
//                 console.error(error)
//             })
//     })
// }

// const createUserWithEmail = (name: string, email: string, password: string, image: string, completion: (user: UserModel) => void) => {
//     firebase.auth()
//         .createUserWithEmailAndPassword(email, password)
//         .then(async (userCredential: any) => {
//             const currentUser = currentAuthUser();
//             if (!currentUser) throw Error("No user");
//             const userData = {
//                 id: currentUser.uid,
//                 name: name,
//                 email: currentUser.email,
//                 image: image
//             }
//             await remote.set.doc("users", currentUser.uid, userData)
//             completion(userData);
//         })
// }

const signInWithEmail = (email: string, password: string) => {
    firebase.auth()
        .signInWithEmailAndPassword(email, password)
        .then(async (userCredential: any) => {
            const user = await fetchUser();
            if (!user) throw Error("No user");

            return user
        })
}

const signOut = async () => {
    await firebase.auth().signOut()
}

const currentAddress = async () => {
    const currentUser = currentAuthUser();
    if (!currentUser) throw Error("Auth Error: User is not logged in");

    const data = await remote.get.doc("users", currentUser.uid);

    return data.address;
}

const fetchUser = async (): Promise<UserModel> => {
    const currentUser = currentAuthUser();
    if (!currentUser) throw Error("Auth Error: User is not logged in");

    const address = await currentAddress()

    const data = await basin.api
        .bucket("public_profile")
        .owner(address)
        .data();

    const model = data[0].data

    const fetchedUser = {
        address: address,
        name: model.name,
        image: model.image
    };

    return fetchedUser;
}

const fetchPrimaryAddress = async (): Promise<string> => {
    const currentUser = currentAuthUser();
    if (!currentUser) throw Error("Auth Error: User is not logged in");

    const data = await remote.get.sub.doc("users", currentUser.uid, "keys", "primary");

    return data.public_address;
}

const onStateChange = (completion: (user: firebase.User | null) => void) => {
    firebase.auth()
        .onAuthStateChanged(user => {
            completion(user);
        });
}

const currentAuthUser = () => {
    return firebase.auth().currentUser;
}


const isLoggedIn = () => currentAuthUser() != null

const auth = {
    // createUserWithEmail,
    // signInWithGoogle,
    signInWithEmail,
    signOut,
    currentAuthUser,
    onStateChange,
    fetchUser,
    fetchPrimaryAddress,
    currentAddress,
    isLoggedIn
}

export default auth;