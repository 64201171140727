import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import NoPage from './pages/NoPage';
import OnboardingView from './pages/onboarding/OnboardingView';
import LayoutView from './pages/Layout';
import { UserModel } from './models/UserModel';
import auth from './services/auth';
import PermissionsView from './pages/permissions/PermissionView';
import { Protected } from './pages/components/protected';

enum AppStates {
  Loading,
  Onboarding,
  Exploring
}

interface AppProps {
  state: AppStates;
  currentUser: UserModel | undefined;
}

function App() {
  const [props, setProps] = useState<AppProps>({
    state: AppStates.Loading,
    currentUser: undefined
  });

  useEffect(() => {
    // auth.signOut(() => {})
    if (props.state === AppStates.Loading) {
      auth.onStateChange(async (user) => {
        if (user) {
          try {
            const user = await auth.fetchUser();

            setProps({
              state: AppStates.Exploring,
              currentUser: user
            });
          } catch {
            auth.signOut();
          }
        } else {
          setProps({
            state: AppStates.Onboarding,
            currentUser: undefined
          });
        }
      })
    }
  }, [props]);

  const PermissionsRequest = (user?: UserModel) => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const providerAddress = params.get('provider');
    const ownerAddress = params.get('owner');
    const buckets = params.get('buckets')?.split(",");
    if (user && providerAddress !== null && ownerAddress !== null && buckets != null && ownerAddress === user.address) {
      return PermissionsView(user, providerAddress, buckets)
    }
    return null
  };

  const ProtectedNoPage = () => {
    if (props.state === AppStates.Loading) return null;

    return <Protected>
      <LayoutView user={props.currentUser}>
        <NoPage />{ }
      </LayoutView>
    </Protected>
  }

  const ProtectedPermissionsPage = () => {
    return <Protected>
      {PermissionsRequest(props.currentUser)}
    </Protected>
  }

  const ProtectedOnboardingPage = () => {
    if (props.state === AppStates.Loading) return null;

    if (!auth.isLoggedIn()) {
      return <OnboardingView />
    } else {
      return <Navigate to="/" />
    }
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={ProtectedNoPage()} />
          <Route path="login" element={ProtectedOnboardingPage()} />
          <Route path="apps" element={ProtectedNoPage()} />
          <Route path="wallet" element={ProtectedNoPage()} />
          <Route path="settings" element={ProtectedNoPage()} />
          <Route path="permissions" element={ProtectedPermissionsPage()} />
          <Route path="*" element={ProtectedNoPage()} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;