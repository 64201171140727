import React from "react";
import { Navigate } from "react-router-dom";
import auth from "../../services/auth";

const Protected = (props: any) => {
    if (!auth.isLoggedIn()) {
        return <Navigate to='/login' />
    }
    return props.children;
}

export { Protected }