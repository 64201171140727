import React, { useEffect, useState } from 'react';
import { MainFooter } from '../components/footer';
import { AnnounceButton, OutlineButton } from '../components/buttons';
import { ImageSize } from '../components/images';
import { CircleImage } from '../components/images';
import { UserModel } from '../../models/UserModel';
import { ProviderModel } from '../../models/ProviderModel';
import api from '../../services/api';
import functions from '../../services/functions';
import { Bucket } from 'open-basin';
import basin from '../../basin';
import { BounceLoader } from 'react-spinners';

// http://localhost:3000/permissions?provider=0x025674905fBEd80f7D08689D5eF1916F5c5Bd36d&owner=0x9dD3709424b9D4f80810F3c3f6a85826b102D491&buckets=public_profile

enum RequestState {
    Success = "Success",
    Error = "Error"
}

export const PermissionsView = (user: UserModel, providerAddress: string, bucketIds: string[]) => {
    const [provider, setProvider] = useState<ProviderModel | null>(null);
    const [buckets, setBucket] = useState<Bucket[] | undefined>(undefined);
    const [requesting, setRequesting] = useState(false);
    const [resultState, setResultState] = useState<RequestState | null>(null);

    useEffect(() => {
        api.getProvider(providerAddress).then(result => setProvider(result));
        basin.api.buckets(bucketIds).get().then(result => setBucket(result));
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    const PermissionsBody = () => {
        if (!provider || !buckets) return null

        return <div className='center-body'>
            {CircleImage(provider.image, "logo", ImageSize.xlarge)}
            <br />
            <br />
            <div className='title-group'>
                <p className='sub-heading'>{provider.name + " would like access to"}</p>
                <p className='heading'>{buckets.map(b => b.name).join(", ")}</p>
            </div>
            <br />
            <AnnounceButton title="Confirm Access" type="fill" action={() => {
                functions
                    .requestBucketsAuthorizations(buckets.map(b => b.id), providerAddress)
                    .then(result => {
                        setRequesting(false)
                        setResultState(RequestState.Success)
                    }).catch(err => {
                        setRequesting(false)
                        setResultState(RequestState.Error)
                    })
                setRequesting(true)
            }} />
            <br />
            <OutlineButton title="Deny" type="fill" action={() => {
                setResultState(RequestState.Error)
            }} />
        </div>
    }

    const Body = () => {
        if (requesting) {
            return <div className='center'>
                <BounceLoader className='center' color="#FFFFFF" />
            </div>
        } else if (!resultState) {
            return <PermissionsBody />
        } else if (resultState === RequestState.Success) {
            return <div>
                <p className='heading'>Access Confirmed</p>
            </div>
        } else {
            return <div>
                <p className='heading'>Error</p>
            </div>
        }
    }

    return (
        <div>
            <div className='header' />
            <div className='center-body'>
                <Body />
            </div>
            <br />
            <MainFooter />
        </div>
    );
};

export default PermissionsView;