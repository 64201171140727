import React, { useEffect, useState } from 'react';
import { MainFooter } from '../components/footer';
import { SignInView } from './SignInViews';
import { AnnounceButton, OutlineButton } from '../components/buttons';
import { ImageSize, SquareImage } from '../components/images';
const logo = require('../assets/logo-black.png');

enum OnboardingStage {
    Greeting,
    SignIn,
    SignUp
}

export const OnboardingView = () => {
    useEffect(() => {}, []);
    const [stage, setStage] = useState(OnboardingStage.Greeting);

    const OnboardBox = () => {
        switch (stage) {
            case OnboardingStage.Greeting:
                return <OnboardingActionView />
            case OnboardingStage.SignUp:
                return null
            case OnboardingStage.SignIn:
                return <SignInView />
        }
    }

    const OnboardingActionView = () => {
        return <div>
            {
            // Uncomment the below to enable account creation
            // <AnnounceButton title="Get Started" type="fill" action={() => {
            //     setStage(OnboardingStage.SignUp);
            // }} />
            }
            <br />
            <OutlineButton title="Log In" type="fill" action={() => {
                setStage(OnboardingStage.SignIn);
            }} />
        </div>
    }

    const TitleView = () => {
        switch (stage) {
            case OnboardingStage.Greeting:
                return <div>
                    {SquareImage(logo, "logo", ImageSize.xlarge)}
                    <div className='title-group'>
                        <p className='heading'>Welcome to Open Basin</p>
                        <p className='sub-heading'>Take your data with you</p>
                    </div>
                </div>
            case OnboardingStage.SignUp:
                return null
            case OnboardingStage.SignIn:
                return <div>
                    {SquareImage(logo, "logo", ImageSize.xlarge)}
                    <div className='title-group'>
                        <p className='heading'>Welcome Back</p>
                    </div>
                </div>
        }
    }

    return (
        <div>
            <div className='header' />
            <div className='center-body'>
                <TitleView />
                <br />
                <div>
                    <OnboardBox />
                </div>
            </div>
            <br />
            <MainFooter />
        </div>
    );
};

export default OnboardingView;